import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router'

import Head from 'next/head'
import Script from 'next/script'

import {
  Pane
} from 'evergreen-ui';

import splitbee from '@splitbee/web';
import toast, { Toaster } from 'react-hot-toast';

import 'bootstrap/dist/css/bootstrap.css'; // Add this line
import 'animate.css';
import '../styles/globals.css'

const config = require("../utils/config");

export default function MyApp({ Component, pageProps }) {

  useEffect(() => {
    splitbee.init();
    (function (c, l, a, r, i, t, y) { c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i; y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y); })(window, document, "clarity", "script", "b7htkf07ym");

    (() => {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: 2955411, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    })();


    (function (d, u, h, s) {
      h = d.getElementsByTagName('head')[0];
      s = d.createElement('script');
      s.async = 1;
      s.src = u + new Date().getTime();
      h.appendChild(s);
    })(document, 'https://grow.clearbitjs.com/api/pixel.js?v=');
  }, [])


  return (
    <Pane>
      <Head>
        <link rel="shortcut icon" href={config.Product.logo} type="image/x-icon" />
        <title>{config.Product.title}</title>

        <meta name="title" content={config.Product.title} />
        <meta name="description" content={config.Product.description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={config.Product.url} />
        <meta property="og:title" content={config.Product.title} />
        <meta property="og:description" content={config.Product.description} />
        <meta property="og:image" content={config.Product.cover} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={config.Product.url} />
        <meta property="twitter:title" content={config.Product.title} />
        <meta property="twitter:description" content={config.Product.description} />
        <meta property="twitter:image" content={config.Product.cover} />
      </Head>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Script type="module" src="https://unpkg.com/learnir-exp-module@latest/dist/learnir-exp-module/learnir-exp-module.esm.js"></Script>
      <Pane data-theme="light">
        <Component {...pageProps} />
      </Pane>
    </Pane>
  )
}

