
let Product = {
    name: 'Learnir',
    title: "A learning development platform",
    description: "Learnir enables organizations crush the continious work of building and managing the best of learning portals from management to delivery.",
    url: 'https://learnir.co',

    logo: '/logo.svg',
    cover: '/cover.png',
    location: "The Internet",
    color: '#15b67d',

    emails: {
        support: `support@learnir.co`,
        team: `team@$learnir.co`,
        sales: `sales@learnir.co`
    },
}

module.exports = {
    Product
}
